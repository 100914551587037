import "./style.css";
import sustainability from "./sustainability.jpg";
import project from "./project.jpg";
import design from "./design.jpg";
import estate from "./estate.jpg";
import construction from "./construction.jpg";
import development from "./development.jpg";

const cardDetails = [
  {
    title: "Development",
    description: "Create the space that shape the communities and drive growth",
    imgUrl: development,
  },
  {
    title: "Architecture & Design",
    description:
      "Design environments that inspire people and elevate performance.",
    imgUrl: design,
  },
  {
    title: "Project Management",
    description:
      "Lead complex projects from concept to completion with confidence.",
    imgUrl: project,
  },
  {
    title: "Construction",
    description:
      "Build with precision and purpose, balancing quality, safety, and effieciency.",
    imgUrl: construction,
  },
  {
    title: "Sustainability",
    description:
      "Embrace innovation and responsibility to create a better built environment.",
    imgUrl: sustainability,
  },
  {
    title: "Real Estate Advisory",
    description:
      "Leverage data and insight to make informed real estate decisions.",
    imgUrl: estate,
  },
];

const ImageCard = () => {
  return (
    <div className='cards'>
      <div className='card-details'>
        {cardDetails.map((card, index) => {
          return (
            <div className='image-card' key={index}>
              <div className='img-section'>
                <img src={card.imgUrl} alt='' />
              </div>
              <div className='card-title'>{card.title}</div>
              <div className='card-caption'>{card.description}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ImageCard;
