import React from "react";

const Footer = () => {
  return (
    <div>
      <div style={{ height: "600px" }}></div>
    </div>
  );
};

export default Footer;
