import "./style.css";
import ambition from "./ambition.jpg";

import React from "react";

const SideHero = () => {
  return (
    <div className='side-heros'>
      <div className='side'>
        <div className='left-segment'>
          <img src={ambition} alt='' />
        </div>
        <div className='right-segment'>
          <div className='info-detail'>
            <div className='mission-title'>Your ambitions are our mission</div>
            <p>
              We're more than a consultancy - we're yout trusted partner,
              dedicated to helping you achieve your real estate goals. Our
              commitment to your success is unwavering, our expertise is
              unmatched, and our perspective is always diverse. We're here to
              guide you through today's complex real estate landscape with
              clarity, confidence, and creativity. Together, we'll build better
              futures.
            </p>
          </div>
          <div className='btn-explore'>Explore our services</div>
        </div>
      </div>
    </div>
  );
};

export default SideHero;
