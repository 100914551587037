import Navbar from "./components/navbar/Navbar";
import Hero from "./components/hero/Hero";
import Header from "./components/header/Header";
import ImageCard from "./components/imagecard/ImageCard";
import SideHero from "./components/sidehero/SideHero";
import Footer from "./components/footer/Footer";
const App = () => {
  return (
    <div className='App'>
      <Navbar />
      <Hero />
      <Header />
      <ImageCard />
      <SideHero />
      <Footer />
    </div>
  );
};

export default App;
