window.addEventListener("load", () => {
  const heroTitle = document.querySelectorAll(".hero-title");
  const heroCaption = document.querySelectorAll(".hero-caption");
  console.log(heroTitle);
  heroTitle.forEach((title) => {
    title.classList.add("show");
  });
  heroCaption.forEach((caption) => {
    caption.classList.add("show");
    console.log(heroCaption);
  });
});
