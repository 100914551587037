import "./style.css";

const Header = () => {
  return (
    <div className='header'>
      <header>
        <h1>
          Comprehenseive solutions for every state of the real estate lifecycle
        </h1>
        <p>
          From development and design to the project management and
          construction, we offer a full spectrum of services to help you realize
          your vision and maximize your investment. Our deep expertise,
          collaborative approach, and unwaivering commitment to excellence set
          us apart ensuring your success at every turn.
        </p>
      </header>
    </div>
  );
};

export default Header;
