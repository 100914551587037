import "./style.css";
import "./script";

const Hero = () => {
  return (
    <div className='hero-section'>
      <div className='hero'>
        <div className='hero-title'>
          Property services that make a difference
        </div>
        <div className='hero-caption'>
          <p>
            We provide professional Property management, maintenance and
            construction services to commercial and residential property owners
            in South Africa
          </p>
        </div>
        <div className='btn'>Get a Quote</div>
      </div>
    </div>
  );
};

export default Hero;
