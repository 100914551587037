import "./style.css";

const Navbar = () => {
  return (
    <div className='nav'>
      <div className='logo'>AfrikaPro Group</div>
      <div className='nav-items'>
        <ul className='nav-menu'>
          <li>Home</li>
          <li>Services</li>
          <li>About Us</li>
          <li>FAQ</li>
          <li>Contact</li>
          <li>Get a Quote</li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
